
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "@vue/runtime-core";
import Empty from "../../components/Empty.vue";
import services from "../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonRefresher,
    IonRefresherContent,
    Empty
  },
  setup() {
    const state = reactive({
      contentLoading: false,
      currentTab: 0,
      isScrollDisabled: false,
      festival: {
        name: "festival",
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1,
      },
      emptyMessage: "축제가 없습니다.",
    });

    const fetch = async (event: any = null, loadMore = false) => {
      const params = {
        size: state.festival.size,
        page: state.festival.page,
      };
      if (state.currentTab == 0) {
        state.emptyMessage = "축제가 없습니다.";
        await services.festivalFineAll(params).then(
          (response) => {
            const { data } = response;
            data.items.forEach((item: any) => {
              if (new Date().getTime() < new Date(item.startDate).getTime()) {
                item.status = "예정";
              } else if (
                new Date(item.endDate).getTime() < new Date().getTime()
              ) {
                item.status = "종료";
              } else {
                item.status = "진행 중";
              }
            });
            if (event) {
              if (event.type == "ion-refresh") {
                state.festival.items = [];
              }
            }

            data.items?.some((item: object) => {
              state.festival.items.push(item);
            });

            state.festival.totalItems = data.totalItems;
            state.festival.totalPages = data.totalPages;
            state.contentLoading = false;
            state.festival.loading = false;
            state.festival.page++;
            if (event) {
              if (data.items.length == 0 && loadMore) {
                state.isScrollDisabled = true;
              }
            }
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            state.contentLoading = false;
            state.festival.loading = false;
            if (event) {
              if (event.type == "ion-refresh") {
                state.festival.items = [];
              }
            }
            state.isScrollDisabled = true;
          }
        );
      } else if (state.currentTab == 1) {
        state.emptyMessage = "진행중인 축제가 없습니다.";
        await services.festivalProgress(params).then(
          (response) => {
            const { data } = response;
            console.log(data);
            data.items.forEach((item: any) => {
              item.status = "진행 중";
            });

            if (event) {
              if (event.type == "ion-refresh") {
                state.festival.items = [];
              }
            }

            data.items?.some((item: object) => {
              state.festival.items.push(item);
            });

            state.festival.totalItems = data.totalItems;
            state.festival.totalPages = data.totalPages;
            state.contentLoading = false;
            state.festival.loading = false;
            state.festival.page++;
            if (event) {
              if (data.items.length == 0 && loadMore) {
                state.isScrollDisabled = true;
              }
            }
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            state.contentLoading = false;
            state.festival.loading = false;
            if (event) {
              if (event.type == "ion-refresh") {
                state.festival.items = [];
              }
            }
            state.isScrollDisabled = true;
          }
        );
      } else if (state.currentTab == 2) {
        state.emptyMessage = "예정인 축제가 없습니다.";
        await services.festivalStandby(params).then(
          (response) => {
            const { data } = response;
            console.log(data);
            data.items.forEach((item: any) => {
              item.status = "예정";
            });

            if (event) {
              if (event.type == "ion-refresh") {
                state.festival.items = [];
              }
            }

            data.items?.some((item: object) => {
              state.festival.items.push(item);
            });

            state.festival.totalItems = data.totalItems;
            state.festival.totalPages = data.totalPages;
            state.contentLoading = false;
            state.festival.loading = false;
            state.festival.page++;
            if (event) {
              if (data.items.length == 0 && loadMore) {
                state.isScrollDisabled = true;
              }
            }
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            state.contentLoading = false;
            state.festival.loading = false;
            if (event) {
              if (event.type == "ion-refresh") {
                state.festival.items = [];
              }
            }
            state.isScrollDisabled = true;
          }
        );
      } else if (state.currentTab == 3) {
        state.emptyMessage = "종료된 축제가 없습니다.";
        await services.festivalEnd(params).then(
          (response) => {
            const { data } = response;
            console.log(data);
            data.items.forEach((item: any) => {
              item.status = "종료";
            });

            if (event) {
              if (event.type == "ion-refresh") {
                state.festival.items = [];
              }
            }

            data.items?.some((item: object) => {
              state.festival.items.push(item);
            });

            state.festival.totalItems = data.totalItems;
            state.festival.totalPages = data.totalPages;
            state.contentLoading = false;
            state.festival.loading = false;
            state.festival.page++;
            if (event) {
              if (data.items.length == 0 && loadMore) {
                state.isScrollDisabled = true;
              }
            }
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            state.contentLoading = false;
            state.festival.loading = false;
            if (event) {
              if (event.type == "ion-refresh") {
                state.festival.items = [];
              }
            }
            state.isScrollDisabled = true;
          }
        );
      }
    };

    const onTab = (tab: number) => {
      state.currentTab = tab;
      // if (tab == "progress") {
      //   analytics.setScreenName(screenName);
      // } else {
      //   analytics.setScreenName(screenNameClose);
      // }

      state.festival.items = [];
      state.festival.loading = true;
      state.festival.page = 1;
      state.festival.size = 20;
      state.isScrollDisabled = false;

      fetch();
    };

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    // onMounted
    onMounted(() => {
      state.festival.items = [];
      fetch();
    });

    return { state, onLoadData, onTab };
  },
});
